.top-container{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.30) 76.99%, rgba(0, 0, 0, 0.00) 95.19%),url(../../img//topcontainer.png);
    background-repeat: no-repeat;
    background-size:cover;
    height: 100vh;
    width: 100vw;
}
.coming{
    animation: coming  5s 3.3s linear;
}
@keyframes coming{
    0%{
        transform: translateY(-20%);
        opacity: 0;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}
.lans{
    transition: 3s;
}
.english{
    background-image: url('../../img/english.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transition: 3s;
}
.french{
    background-image: url('../../img/french.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transition: 3s;

}
.spanish{
    background-image: url('../../img/spanish.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transition: 3s;

}
.top-container-image{
    position: absolute;
    background-image:url(../../img/container-image.png);
    background-repeat: no-repeat;
    background-size:100% 100%;
    height: 100%;
    width: 100%;
    z-index: 4;
}
.hamza{
    position: relative;
    height: 80%;
    width: 100%;
}
.hamza::after{
    content: "";
    position: absolute;
    width: 80%;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #121EE4;
    border-top-right-radius: 10%;
    border-top-left-radius: 10%;
    z-index: 1;

}

.loading-spinner {
    border: 5px solid white;
    border-top: 5px solid #121EE4; /* Change the color as needed */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
.under::after{
    content: "";
    position: absolute;
    bottom: 0px;
    left: 8%;
    background-color: #121EE4;
    height: 25px;
    transform: rotate(-4deg);
    clip-path: polygon(36% 64%, 100% 28%, 100% 15%, 0 32%, 0 100%);
    width: 105%;
}
.circle{
    border-radius: 50%;
    background-image: radial-gradient(circle, #121EE4 0% 20%,#3C47FB 20% 40%, #207BF1 40% 55%,#4B91EC 55% 80%,#fff 80% 100%);
}

.red-car .shadow{
    filter: drop-shadow(1px 1px 10px  #000);
  left: 12%;
  z-index: 4;
  margin-top:30%;
}
.red-car .shadow span{
    position: absolute;
    content: '';
    width: 25vw;
    height: 6vw;
    background-color: #000;
    opacity: 0.6;
    box-shadow: 10px 10px 15px 15px #000;
    z-index: 2;
    clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
    transform: translateX(12vw) translateY(-2vw);

}

.minicar{
    clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 64% 75%, 50% 99%, 35% 75%, 0% 75%);
    padding-bottom: 20px;
}
.minicarcircle{
    height: 13px;
    width: 25px;
    padding: 3px;
    background-color: #000;
    border-radius: 80%;
    background-image: radial-gradient(closest-side, #121EE4 0% 50%,#fff 60% 80%,#fff 90% 100%);
}
@media(max-width:700px){
    .top-container{
        background: #F2F6FF;
        background-repeat: no-repeat;
        background-size:cover;
        height: 70vh;
        width: 100vw;
    }
   
    .under::after{
        height: 13px !important;
    }
   
    .minicar{
 padding-bottom: 10px !important;
    }
    .minicarcircle{
        height: 9px !important;
        width:15px  !important;
    }
    .exeptlb{
        border-radius: 3px 3px 3px 0rem !important;
    }
    .prix::after{
        font-size: 0.3rem !important; 
        margin-left: 1.5px !important;
    }
    .prix>span::after{
        font-size: 0.5rem !important; 
        margin-left: 1.5px !important;
    }
    .prix1::after{
        font-size: 0.3rem !important; 
        margin-left: 1.5px !important;
    }
    .cart{
        height: 25vh !important;
        border-radius: 15px !important;
    }
    
    .bookul li{
    font-size:0.7rem !important;
    }/* 1.46775rem */
    .bookul li::before {
        font-size: 0.6rem !important;
        margin-right: 5px;
      }
     
}
@media(max-width:500px){
    
    .placeholder {
        display: block !important;
        position: absolute;
        pointer-events: none;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        color: #515151; /* Adjust color as needed */
      }
      .top-container{
        background: #F2F6FF;
        background-repeat: no-repeat;
        background-size:cover;
        height: 30vh;
        width: 100vw;
    }
    .under::after{
        height: 7px !important;
    }
    .cart{
        margin-top: 20px;
        margin-bottom: 20px;
     }
    .minicar{
 padding-bottom: 5px !important;
 z-index: 100 !important;

    }
    .minicarcircle{
        height: 5px !important;
        width:8px  !important;
    } 
    .exeptlb{
        border-radius: 2px 2px 2px 0rem !important;
    }
    .prix::after{
        font-size: 0.3rem !important; 
        margin-left: 0.75px !important;
    }
    .prix>span::after{
        font-size: 0.5rem !important; 
        margin-left: 0.75px !important;
    }
    .prix1::after{
        font-size: 0.15rem !important; 
        margin-left: 0.75px !important;
    }
    .cart{
        min-width: 70vw !important;
        max-width: 70vw !important;
        height: 120px !important;
        border-radius: 10px;
    }
   
    
    .bookul li{
    font-size:0.6rem !important;
    }/* 1.46775rem */
    .bookul li::before {
        font-size: 0.6rem !important;
        margin-right: 3px;
      }
}
@keyframes topbottom{
    0%{
        transform: translateY(-10%);
    }
    100%{
        transform: translateY(10%);
    }
}
@keyframes widthmore{
    0%{
        width:30%;
    }
    100%{
        width:10%;
    }
}
.nav{
    border-top-right-radius: 0;
}
.obgsheet{
    text-transform: uppercase;
    text-decoration: underline;
}
.cars::-webkit-scrollbar{
    width: 0 !important;
}
.carts::-webkit-scrollbar{
    width: 0 !important;
}
.views::-webkit-scrollbar{
    width: 0 !important;
}
@keyframes movekey{
    0%{
        transform: translateZ(-100%) ;
        margin-left: 40%;
        height:0% ;
    }
    10%{
        transform: translateZ(-90%);
        height:10% ;
        margin-left: 30%;

    }
    20%{
        transform: translateZ(-80%);
        height:30% ;
        margin-left: 30%;

    }
    30%{
        transform: translateZ(-70%);
        height:40% ;
        margin-left: 10%;
    }
    40%{
        transform: translateZ(-60%);
        height:50% ;
        margin-left: 10%;
    }
    50%{
        transform: translateZ(-50%);
        height:60% ;
    }
    60%{
        transform: translateZ(-40%);
        height:70% ;
    }
    70%{
        transform: translateZ(-30%);
    }
    80%{
        transform: translateZ(-20%);
    }
    90%{
        transform: translateZ(-10%);
    }
    100%{
        transform: translateZ(1%);
    }
}
@keyframes movekey1{
  0%{
    height: 2vw;
    width: 15vw;
    margin-top: -4%;
    margin-left: 5%
  }
  100%{
    height: 6vw;
    margin-top: 0%;
    margin-left: 5%
  }
}
.prix span{
    color:#121EE4;
    font-size: 0.6rem; 
    margin-left: 3px;
  font-weight: 300;
}
.prix span::after{
    content:'€';
    color:#121EE4;
    font-size: 0.6rem; 
    margin-left: 3px;
  font-weight: 600;
}
.formscroll{
    transition: 2s;
}
.cart{
    z-index: 200;
}
.total{
    filter: blur(0.1px);
}
.cart1{
    background-image: url('../../img/Aboutus/blog1.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}

.cart2{
    background-image: url('../../img/Aboutus/blog2.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}
.cart3{
    background-image: url('../../img/Aboutus/blog3.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}
.cart4{
    background-image: url('../../img/Aboutus/car\ rental\ near\ me\ in\ Morocco.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}
.cart5{
    background-image: url('../../img/Aboutus/Marrakech\ CarRental\ Guide\ Navigating\ the\ Red\ City\ with\ Confidence.png');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}
.cart55{
    background-image: url('../../img/Aboutus/Marrakech\ CarRental\ Guide\ Navigating\ the\ Red\ City\ with\ Confidence.png');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    border-radius: 25px;
}
.cart6{
    background-image: url('../../img/Aboutus/Agadir\,Taghazout\,Hidden\ Beaches\,\ Charming\ Villages.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}
.cart66{
    background-image: url('../../img/Aboutus/Agadir\,Taghazout\,Hidden\ Beaches\,\ Charming\ Villages.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    border-radius: 25px;
}
.cart7{
    background-image: url('../../img/Aboutus/Best\ Car\ Rental\ Guide\ for\ Morocco\ Safe\ Driving\ Tips\ &\ Essential\ Info\ for\ Tourists.png');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}
.cart8{
    background-image: url('../../img/Aboutus/tangier\,Morocco\,rental\ car.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}
.cart88{
    background-image: url('../../img/Aboutus/tangier\,Morocco\,rental\ car.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    border-radius: 25px;
}
.cart9{
    background-image: url('../../img/Aboutus/casablanca\ rental\ cars.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}
.cart99{
    background-image: url('../../img/Aboutus/casablanca\ rental\ cars.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    border-radius: 25px;
}
.cart10{
    background-image: url('../../img/Aboutus/rental\ red\ car\ morocco.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}

.cart100{
    background-image: url('../../img/Aboutus/rental\ red\ car\ morocco.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    border-radius: 25px;
}
.cart11{
    background-image: url('../../img/Aboutus/adventure\ car\ range\ morocco.png');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}
.cart111{
    background-image: url('../../img/Aboutus/adventure\ car\ range\ morocco.png');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    border-radius: 25px;
}
.cart12{
    background-image: url('../../img/Aboutus/Navigate\ Morocco\ with\ MY\ DRIVE\ CAR\'s\ Monthly\ Solutions.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}
.cart13{
    background-image: url('../../img/Aboutus/Extended\ Stays\,\ Unending\ Experiences\ Explore\ Morocco\ at\ Your\ Own\ Pace\ with\ MY\ DRIVE\ CAR.png');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}
.cart14{
    background-image: url('../../img/Aboutus/rental\ cars\ essouira\ or\ Erg\ \ Chebbi\ or\ Chefchaouen\ or\ Marrakech\ from\ morocco.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}
.cart15{
    background-image: url('../../img/Aboutus/Morocco Rugged Terrain with MY DRIVE CAR 4x4s.png');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}
.cart16{
    background-image: url('../../img/Aboutus/Romantic\ drives\ in\ Morocco.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}
.cart17{
    background-image: url('../../img/Aboutus/morocco\ sahara\ rental\ car.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}
.cart18{
    background-image: url('../../img/Aboutus/Moroccan\ experience\ MY\ DRIVE\ CAR\ Exotic\ car\ rentals.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}
.cart19{
    background-image: url('../../img/Aboutus/5\ Affordable\ Road\ Trips\ in\ Morocco\ You\ Can\ Do\ in\ a\ Week.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}
.cart20{
    background-image: url('../../img/Aboutus/Essential\ Tips\ for\ City\ Streets\ and\ Desert\ Highways.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}
.cart21{
    background-image: url('../../img/Aboutus/Morocco\ Car\ Rental\ Made\ Easy\ Essential\ FAqs\ Answered\ for\ a\ Safe\ &\ Fun\ Adventure.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}
.cart22{
    background-image: url('../../img/Aboutus/Buckle\ Up\ for\ Adventure\ Family\ Road\ Trip\ Gems\ in\ Morocco.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}
.cart23{
    background-image: url('../../img/Aboutus/Hidden\ Gems\ &\ Desert\ Escapes\ A\ Romantic\ Road\ Trip\ Through\ Morocco.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}
.cart24{
    background-image: url('../../img/Aboutus/desert\ sahara\ morocco\ rental\ car.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}
.cart25{
    background-image: url('../../img/Aboutus/Conquering\ Morocco\'s\ Trails\ The\ Essential\ Guide\ to\ Choosing\ Your\ Camping\ Rental\ Car.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}
.cart26{
    background-image: url('../../img/Aboutus/Explore\ Morocco\'s\ Hidden\ Gems\ A\ Self-Drive\ Adventure\ Guide.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    min-width: 50vw;
    max-width: 50vw;
    height: 40vh;
    border-radius: 25px;
}
.text-shadow{
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.blog{
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}

#bgimage1{
    background-image:url('../../img/Aboutus/blog1.jpg') ;
}
#bgimage2{
    background-image:url('../../img/Aboutus/blog2.jpg') ;
}
#bgimage3{
    background-image:url('../../img/Aboutus/blog3.jpg') ;
}
#bgimage4{
    background-image:url('../../img/Aboutus/car\ rental\ near\ me\ in\ Morocco.jpg') ;
}
#bgimage5{
    background-image:url('../../img/Aboutus/Marrakech\ CarRental\ Guide\ Navigating\ the\ Red\ City\ with\ Confidence.png') ;
}
#bgimage6{
    background-image:url('../../img/Aboutus/Agadir\,Taghazout\,Hidden\ Beaches\,\ Charming\ Villages.jpg') ;
}
#bgimage7{
    background-image:url('../../img/Aboutus/Best\ Car\ Rental\ Guide\ for\ Morocco\ Safe\ Driving\ Tips\ &\ Essential\ Info\ for\ Tourists.png') ;
}
#bgimage8{
    background-image:url('../../img/Aboutus/tangier\,Morocco\,rental\ car.jpg') ;
}
#bgimage9{
    background-image:url('../../img/Aboutus/casablanca\ rental\ cars.jpg') ;
}
#bgimage10{
    background-image:url('../../img/Aboutus/rental\ red\ car\ morocco.jpg') ;
}
#bgimage11{
    background-image:url('../../img/Aboutus/adventure\ car\ range\ morocco.png') ;
}
#bgimage12{
    background-image:url('../../img/Aboutus/Navigate\ Morocco\ with\ MY\ DRIVE\ CAR\'s\ Monthly\ Solutions.jpg') ;
}
#bgimage13{
    background-image:url('../../img/Aboutus/Extended\ Stays\,\ Unending\ Experiences\ Explore\ Morocco\ at\ Your\ Own\ Pace\ with\ MY\ DRIVE\ CAR.png') ;
}
#bgimage14{
    background-image: url('../../img/Aboutus/rental\ cars\ essouira\ or\ Erg\ \ Chebbi\ or\ Chefchaouen\ or\ Marrakech\ from\ morocco.jpg');
}
#bgimage15{
    background-image: url('../../img/Aboutus/Morocco Rugged Terrain with MY DRIVE CAR 4x4s.png');
}
#bgimage16{
    background-image: url('../../img/Aboutus/Romantic\ drives\ in\ Morocco.jpg');
}
#bgimage17{
    background-image: url('../../img/Aboutus/morocco\ sahara\ rental\ car.jpg');
}
#bgimage18{
    background-image: url('../../img/Aboutus/Moroccan\ experience\ MY\ DRIVE\ CAR\ Exotic\ car\ rentals.jpg');
}
#bgimage19{
    background-image: url('../../img/Aboutus/5\ Affordable\ Road\ Trips\ in\ Morocco\ You\ Can\ Do\ in\ a\ Week.jpg');
}
#bgimage20{
    background-image: url('../../img/Aboutus/Essential\ Tips\ for\ City\ Streets\ and\ Desert\ Highways.jpg');
}
#bgimage21{
    background-image: url('../../img/Aboutus/Morocco\ Car\ Rental\ Made\ Easy\ Essential\ FAqs\ Answered\ for\ a\ Safe\ &\ Fun\ Adventure.jpg');
}
#bgimage22{
    background-image: url('../../img/Aboutus/Buckle\ Up\ for\ Adventure\ Family\ Road\ Trip\ Gems\ in\ Morocco.jpg');
}
#bgimage23{
    background-image: url('../../img/Aboutus/Hidden\ Gems\ &\ Desert\ Escapes\ A\ Romantic\ Road\ Trip\ Through\ Morocco.jpg');
}
#bgimage24{
    background-image: url('../../img/Aboutus/desert\ sahara\ morocco\ rental\ car.jpg');
}
#bgimage25{
    background-image: url('../../img/Aboutus/Conquering\ Morocco\'s\ Trails\ The\ Essential\ Guide\ to\ Choosing\ Your\ Camping\ Rental\ Car.jpg');
}
#bgimage26{
    background-image: url('../../img/Aboutus/Explore\ Morocco\'s\ Hidden\ Gems\ A\ Self-Drive\ Adventure\ Guide.jpg');
}
.bookul li{
color: #121ee4b3;
font-family: Montserrat;
font-size: 1.5rem;
font-style: normal;
font-weight: 400;
line-height: 118%; 
white-space: nowrap;
}/* 1.46775rem */
.bookul li::before {
    content: "\2713"; /* Unicode checkmark character */
    font-size: 1.5em;
    margin-right: 10px;
    color: green;
  }
 
  .placeholder {
   display: none;
  }
  
/* For Webkit (Safari, Google Chrome, etc.) */
.formscroll::-webkit-scrollbar {
    background-color: #868ad6;
    width: 10px; /* You can adjust the width as needed */
  }
  
  .formscroll::-webkit-scrollbar-thumb {
    background-color: #121EE4; /* Change the color here */
    border-radius: 10px; /* You can adjust the border-radius as needed */
  }
  
.nav2::-webkit-scrollbar {
    background-color: #868ad6;
    width: 10px; /* You can adjust the width as needed */
  }
  
  .nav2::-webkit-scrollbar-thumb {
    background-color: #121EE4;
    height: 10px;
    width: 50%; /* Change the color here */
    border-radius: 10px; /* You can adjust the border-radius as needed */
  }
  .beaches-agadir{
    background-image: url('../../img/Aboutus/agadir\ beaches\ morocco\ taghazout.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
  }
  
  
  .coves{
    background-image: url('../../img/Aboutus/hidden\ beaches\ and\ coves\ Agadir\ Taghazout\ Morocco.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  
  .villages{
    background-image: url('../../img/Aboutus/coastal\ villages\ \ agadir\ .png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
  }
  .cuisine{
    background-image: url('../../img/Aboutus/fresh\ seafood\ and\ local\ cuisine\ Agadir\ Morocco.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
  }
  .partner{
    background-image: url('../../img/Aboutus/partner\ car\ morocco\ rental\ cars\ marrakech.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
  }
  .carfix{
    background-image: url('../../img/Aboutus/morocco\ car\ travel\ rental\ fix.webp');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
  }
  .mapmorocco{
    background-image: url('../../img/Aboutus/car\ map\ morocco\ travel\ rental\ cars.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
  }
  /* For Firefox */
 /* styles.css */


  
  /* And also copy these styles from the swiper demo code sandox src->styles.css */

  
 .cart{
    margin-top: 30px;
    margin-bottom: 30px;
 }
  .swiper-slide-active {
    transform: scale(1.2); /* Increase the size of the active slide */
    z-index: 1;
     /* Ensure the active slide is on top */
  }
  .swiper-button-prev,
.swiper-button-next {
  background-color: transparent; /* Set background color as transparent */
  color: #fff; /* Set default text color */
  opacity: 0.7; /* Set default opacity */
  transition: opacity 0.3s ease; /* Add transition for smooth effect */
  cursor: pointer; 
  background-color: white;
  border-radius: 50%; 
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  opacity: 1; /* Increase opacity on hover */
/* Change background color on hover */
}

/* Additional styling for ion-icon */

